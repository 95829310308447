import React, { useEffect } from "react"
import { RouteComponentProps } from "@reach/router"

import { Seo } from "../components/Seo/Seo"
import * as Editor from "../components/Editor"
import { ContainerProps, connector } from "../containers/ecrire.container"

type Props = {
  onMount: Function
} & RouteComponentProps

export const EcrireWrapper: React.FC<Props> = ({ onMount }) => {
  useEffect(() => {
    onMount()
  }, [])

  return (
    <>
      <Seo
        index={false}
        title="Écrire et publier un haïku | Temple du Haïku"
        description="Avec notre éditeur de haïku, vous pouvez créer des haïkus tout en respectant les règles"
      />

      <div className="min-h-screen bg-gray-50">
        <Editor.Header />
        <Editor.Keyboard />
        <Editor.Indicator />
        <Editor.Loader />
        <Editor.Category />
      </div>
    </>
  )
}

export const EcrireContainer: React.FC<
  ContainerProps & RouteComponentProps
> = props => <EcrireWrapper {...props} />

export default connector(EcrireContainer)
